import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import styled from "@emotion/styled"
import Masonry from 'react-masonry-css'

import Layout from '../components/layout'
import Topsection from '../components/topsection'
import Seo from '../components/seo'

const Hometitle = styled.div`
h1 {
  font-size: 6em;
  width: 65vw;
  text-align: left;
}
`

const Text = styled.div`
margin-left: 17.5vw;
width: 65vw;
text-align: left;
margin-top: 12vh;
margin-bottom: 5vh;
font-size: 1.5rem;
font-weight: 400;
letter-spacing: .1px;
line-height: 40px;
p {
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0) 39px, rgba(0,0,0,0.15) 39px, rgba(0,0,0,0.15) 40px);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 39px, rgba(0,0,0,0.15) 39px, rgba(0,0,0,0.15) 40px);
  background-repeat: repeat-y;
  background-size: 100% 40px;
  font-family: 'Texturina', serif;
  font-size: 1.5rem;
  letter-spacing: .5px;
  background-position-y: 46px;
  strong {
    font-family: 'Inter', sans-serif;
    font-size: 1.33rem;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 3px 6px;
  }
}
@media (max-width: 676px) {
  width: 85vw;
  margin-left: 7.5vw;
}
`

const Main = styled.main`
width: 90vw;
padding: 120px 5vw 10vh 5vw;
background-color: rgba(0,0,0,0.033);
h2 {
  font-family: "Freight Big Black Regular";
  line-height: 1em;
  font-size: 1.5rem;
  padding-bottom: 2px;
  margin-top: 24px;
}
h3 {
  font-family: "FreightText Light Italic";
  font-size: 16px;
  line-height: 1.2em;
  padding-bottom: 24px;
}
a {
  display: block;
  border-bottom: 0;
  opacity: 0.7;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
    background-color: #fff;
  }
}
`

const IndexPage = ({data}) => {
  const { edges } = data.allPrismicFullProject
  const breakpointColumnsObj = {
    default: 3,
    1024: 2,
    700: 2,
    599: 1
  }
  return (
    <Layout>
      <Seo title="Hello" />
      {data.allPrismicHomepage.edges.map(({ node }) => {
        return (
        <>
        <Topsection>
        <Hometitle><PrismicRichText field={node.data.title.richText} /></Hometitle>
        
        <Text><PrismicRichText field={node.data.text.richText} /></Text>
        </Topsection>
        </>
        )
      })}

    <Main>
    <Masonry
  breakpointCols={breakpointColumnsObj}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
        {edges.map(({ node }) => {
          return (
            <div key={node.url}>
              <Link to={node.url}>
              <GatsbyImage image={node.data.banner_image.gatsbyImageData} alt="image" objectFit="contain" />
              <h2>{node.data.project_title.text}</h2>
              <h3>{node.data.subtitle.text}</h3>
              </Link>
            </div>
          )
        })}
    </Masonry>
    </Main>
    </Layout>
  )
}

export const query = graphql`
query {
  allPrismicFullProject(sort: {fields: data___date, order: DESC}) {
    edges {
      node {
        url
        id
        data {
          project_title {
            text
          }
          subtitle {
            text
          }
          banner_image {
            gatsbyImageData
          }
        }
      }
    }
  }
  allPrismicHomepage {
    edges {
      node {
        data {
          title {
            richText
          }
          text {
            richText
          }
        }
      }
    }
  }
}
`

export default IndexPage
